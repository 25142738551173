.contact-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    max-width: 400px;
    margin-left: auto;
  }
  
  .contact-form div {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .contact-form label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .contact-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }